exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-hobbies-js": () => import("./../../../src/pages/about/hobbies.js" /* webpackChunkName: "component---src-pages-about-hobbies-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-techproficiencies-js": () => import("./../../../src/pages/about/techproficiencies.js" /* webpackChunkName: "component---src-pages-about-techproficiencies-js" */),
  "component---src-pages-dicerollsimulator-js": () => import("./../../../src/pages/dicerollsimulator.js" /* webpackChunkName: "component---src-pages-dicerollsimulator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kanban-js": () => import("./../../../src/pages/kanban.js" /* webpackChunkName: "component---src-pages-kanban-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

